import {
  FiBox,
  FiDollarSign,
  FiFileText,
  FiPackage,
  FiPrinter,
  FiSend,
  FiTag,
  FiUploadCloud,
  FiUserPlus,
} from 'react-icons/fi';
import { HomeActionsProps } from '../types';
import { AiOutlineUserAdd, AiOutlineUsergroupAdd } from 'react-icons/ai';
import { BsBasket2 } from 'react-icons/bs';

export const homeActions: HomeActionsProps[] = [
  {
    label: 'Adicionar novos usuários',
    href: '/usuario',
    icon: AiOutlineUsergroupAdd,
    codTela: 2,
    customBgColor: 'rgb(177,177,177)',
  },
  {
    label: 'Cadastrar cliente',
    href: '/cliente',
    icon: FiUserPlus,
    codTela: 21,
    customBgColor: '#00C0EF',
  },
  {
    label: 'Cadastrar fornecedor',
    href: '/fornecedor',
    icon: AiOutlineUserAdd,
    codTela: 24,
    customBgColor: '#DD4B39',
  },
  {
    label: 'Cadastrar produto',
    href: '/produto',
    icon: FiPackage,
    codTela: 28,
    customBgColor: '#F39C12',
  },
  {
    label: 'PDV Online',
    href: '/pdv-online',
    icon: BsBasket2,
    codTela: 283,
    buttonDestaque: true,
  },
];

export const homeActionsModal: HomeActionsProps[] = [
  {
    label: 'Entrada de mercadoria (NF)',
    href: '/entrada-de-nfe',
    icon: FiFileText,
    codTela: 60,
    customBgColor: '#9f9f9f',
  },
  {
    label: 'Emitir uma nota fiscal para cliente ',
    href: '/emissao-nfe',
    icon: FiFileText,
    codTela: 47,
    customBgColor: '#9f9f9f',
  },
  {
    label: 'Configurar impressora/balança',
    href: '/regras-de-loja',
    icon: FiPrinter,
    codTela: 25,
    customBgColor: '#9f9f9f',
  },
  {
    label: 'Imprimir uma etiqueta',
    href: '/impressao-etiqueta',
    icon: FiTag,
    codTela: 296,
    customBgColor: '#9f9f9f',
  },
  {
    label: 'Ajustar o estoque de um produto',
    href: '/ajuste-de-estoque',
    icon: FiBox,
    codTela: 30,
    customBgColor: '#9f9f9f',
  },
  {
    label: 'Alterar o preço de um produto',
    href: '/alteracao-de-preco-por-item',
    icon: FiDollarSign,
    codTela: 290,
    customBgColor: '#9f9f9f',
  },
  {
    label: 'Enviar carga para o PDV',
    href: '/carga',
    icon: FiSend,
    codTela: 44,
    customBgColor: '#9f9f9f',
  },
  {
    label: 'Enviar carga para balanças',
    href: '/carga',
    icon: FiUploadCloud,
    codTela: 44,
    customBgColor: '#9f9f9f',
  },
];
