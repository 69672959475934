import { YoutubeAnimation } from '../animation/YoutubeAnimation';
import { HomeLinksProps } from '../types';

export const homeLinks: HomeLinksProps[] = [
  {
    label: 'Conhecendo o menu',
    link: 'https://www.youtube.com/watch?v=LkS-VYHL-o8',
    iconNode: YoutubeAnimation,
  },
  {
    label: 'Consultar, inserir, editar ou excluir registros',
    link: 'https://www.youtube.com/watch?v=df7E_Wcf4D4',
    iconNode: YoutubeAnimation,
  },
  {
    label: 'Primeiro contato com o sistema',
    link: 'https://ajuda.konvix.com.br/portal/pt-br/kb/articles/primeiro-contato-com-konvix-gestao',
  },
  {
    label: 'Criar consulta à um clique',
    link: 'https://ajuda.konvix.com.br/portal/pt-br/kb/articles/cartão-de-consultas-pesquisas-a-um-clique',
  },
  {
    label: 'Gerar relatórios',
    link: 'https://ajuda.konvix.com.br/portal/pt-br/kb/articles/como-gerar-relat%C3%B3rios',
  },
];
