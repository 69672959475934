import Lottie from 'lottie-react';
import youtubeAnimation from './json/youtube.json';
import React from 'react';

export const YoutubeAnimation: React.FC = () => {
  return (
    <div style={{ width: 40, height: 40 }}>
      <Lottie
        animationData={youtubeAnimation}
        loop
        style={{
          width: '100%',
          height: '100%',
        }}
      />
    </div>
  );
};
