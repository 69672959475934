import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;

  .section-home-links {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 0.625rem;

    .container-links {
      border: 0.0625rem solid #57069e;
      padding: 0.625rem;
      border-radius: 0.3125rem;
      height: 3.75rem;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      font-size: medium;
      text-decoration: none;
      text-align: center;
      color: #57069e;

      &:hover {
        box-shadow: 0rem 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
      }
    }
  }

  .section-dashboard {
    display: flex;
    flex-direction: column;

    .content-dashboard {
      display: grid;
      grid-template-columns: 74% 25%;
      gap: 0.625rem;
      width: 100%;

      @media only screen and (max-width: 1399px) {
        display: flex;
        flex-direction: column;
      }

      .left-side {
        display: flex;
        flex-direction: column;
        gap: 0.625rem;

        .cards-top-left {
          display: flex;
          gap: 0.625rem;

          @media only screen and (max-width: 992px) {
            flex-direction: column;
          }
        }

        .cards-bottom-left {
          display: grid;
          grid-template-columns: 65% 34%;
          gap: 0.625rem;
          width: 100%;

          @media only screen and (max-width: 992px) {
            display: flex;
            gap: 0.625rem;
            flex-direction: column;
          }
        }
      }

      .right-side {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;

        @media only screen and (max-width: 992px) {
          gap: 0.625rem;
        }
      }
    }
  }

  .icon-container {
    @media (max-width: 1200px) {
      display: none;
    }
  }

  @media (max-width: 992px) {
    max-width: 1000px !important;
  }
`;

export const ActGridContainer = styled.div`
  display: grid;
  gap: 0.625rem;
  width: 100%;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }

  @media (max-width: 990px) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

    & > :nth-last-child(-n + 2) {
      justify-self: center;
      width: 200px;
    }
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
`;
